import ReactMarkdown from "react-markdown";
import * as R from "remeda";
import InlineCode from "@/components/shared/InlineCode";

interface MarkdownProps {
  text: string;
  openLinksInNewTab?: boolean;
  inline?: boolean;
}

const Markdown = ({ text, openLinksInNewTab, inline = true }: MarkdownProps) => (
  <ReactMarkdown
    allowedElements={[
      "a", "p", "strong", "em", "code", "h1", "h2", "h3", "h4", "h5", "h6",
      "ul", "ol", "li", "blockquote", "hr", "img", "pre", "table", "thead",
      "tbody", "tr", "th", "td", "br"
    ]}
    components={{
      a: ({ ...props }) => (
        <a
          className="tw-text-blue-300 hover:tw-underline tw-font-semibold"
          {...R.omit(props, ["node"])}
        />
      ),
      code: ({ inline: isInline, className, children, ...props }) => {
        // Extract language class if it exists (e.g. language-javascript)
        const match = /language-(\w+)/.exec(className || '');
        const language = match ? match[1] : '';
        
        if (!isInline) {
          return (
            <pre className="tw-bg-[#dedede] tw-px-4 tw-py-2 tw-my-0 tw-rounded tw-overflow-auto">
              <code 
                className={`tw-font-mono tw-text-black tw-text-base ${language ? `language-${language}` : ''}`} 
                {...props}
              >
                {children}
              </code>
            </pre>
          );
        }
        
        return <InlineCode {...props}>{children}</InlineCode>;
      },
      h1: ({ children, ...props }) => (
        <h1 className="tw-text-2xl tw-font-semibold tw-mt-4 tw-mb-2" {...props}>
          {children}
        </h1>
      ),
      h2: ({ children, ...props }) => (
        <h2 className="tw-text-xl tw-font-semibold tw-mt-4 tw-mb-2" {...props}>
          {children}
        </h2>
      ),
      h3: ({ children, ...props }) => (
        <h3 className="tw-text-lg tw-font-semibold tw-mt-3 tw-mb-1" {...props}>
          {children}
        </h3>
      ),
      h4: ({ children, ...props }) => (
        <h4 className="tw-text-base tw-font-semibold tw-mt-3 tw-mb-1" {...props}>
          {children}
        </h4>
      ),
      h5: ({ children, ...props }) => (
        <h5 className="tw-text-sm tw-font-semibold tw-mt-2 tw-mb-1" {...props}>
          {children}
        </h5>
      ),
      h6: ({ children, ...props }) => (
        <h6 className="tw-text-xs tw-font-semibold tw-mt-2 tw-mb-1" {...props}>
          {children}
        </h6>
      ),
      ul: ({ children, ...props }) => (
        <ul className="tw-list-disc tw-pl-5 tw-my-2" {...props}>
          {children}
        </ul>
      ),
      ol: ({ children, ...props }) => (
        <ol className="tw-list-decimal tw-pl-5 tw-my-2" {...props}>
          {children}
        </ol>
      ),
      li: ({ children, ...props }) => (
        <li className="tw-my-1" {...props}>
          {children}
        </li>
      ),
      blockquote: ({ children, ...props }) => (
        <blockquote
          className="tw-border-l-4 tw-border-gray-300 tw-pl-4 tw-italic tw-text-gray-600 tw-my-4"
          {...props}
        >
          {children}
        </blockquote>
      ),
      hr: ({ ...props }) => (
        <hr className="tw-my-4 tw-border-gray-300" {...props} />
      ),
      img: ({ alt, src, ...props }) => (
        <img
          alt={alt}
          src={src}
          className="tw-max-w-full tw-my-4 tw-rounded"
          {...props}
        />
      ),
      table: ({ children, ...props }) => (
        <div className="tw-w-full tw-overflow-x-auto tw-my-4">
          <table
            className="tw-min-w-full tw-border-collapse tw-border tw-border-gray-300"
            {...props}
          >
            {children}
          </table>
        </div>
      ),
      thead: ({ children, ...props }) => (
        <thead className="tw-bg-gray-100" {...props}>
          {children}
        </thead>
      ),
      tbody: ({ children, ...props }) => (
        <tbody {...props}>{children}</tbody>
      ),
      tr: ({ children, ...props }) => (
        <tr className="tw-border-b tw-border-gray-300" {...props}>
          {children}
        </tr>
      ),
      th: ({ children, ...props }) => (
        <th className="tw-px-4 tw-py-2 tw-text-left tw-font-semibold" {...props}>
          {children}
        </th>
      ),
      td: ({ children, ...props }) => (
        <td className="tw-px-4 tw-py-2" {...props}>
          {children}
        </td>
      ),
      ...(inline ? { p: "span" } : {
        p: ({ children, ...props }) => (
          <p className="tw-my-2" {...props}>{children}</p>
        )
      })
    }}
    {...(openLinksInNewTab ? { linkTarget: "_blank" } : {})}
    skipHtml
  >
    {text}
  </ReactMarkdown>
);

export default Markdown;
